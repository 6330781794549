import React from 'react';
import { useTranslation } from 'react-i18next';
import { pullAllBy } from 'lodash';
import { SearchInput, SearchInputData } from '.';
import { useLocationsAPIClient } from '../../hooks/api';
import { useArraySessionStorage } from '../../hooks';
import { Country } from './types';
import { SessionStorageKeys } from '../../constants';

type CountriesInputProps = {
  value?: string;
  onChange?: (e?: string) => void;
  style?: React.CSSProperties;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
};

export const CountriesInput: React.FC<CountriesInputProps> = ({ value, onChange, style, mode, disabled }) => {
  const { t } = useTranslation();

  const locationsAPIClient = useLocationsAPIClient();

  const [countries, countriesLoaded] = useArraySessionStorage<Country>(
    locationsAPIClient.countries,
    SessionStorageKeys.COUNTRIES,
  );

  const getCountriesInputData = (): Promise<Array<SearchInputData>> => {
    if (!countries || countries.length === 0) return Promise.resolve([]);

    const mostUsedCountries: Array<Country> = [
      { name: 'Argentina', code: 'ar' },
      { name: 'Uruguay', code: 'uy' },
      { name: 'United States of America', code: 'us' },
      { name: 'Spain', code: 'es' },
    ];

    const listWithoutMostUsed = pullAllBy([...countries], mostUsedCountries, 'code');

    const countriesList = [...mostUsedCountries, ...listWithoutMostUsed];

    return Promise.resolve(
      countriesList.map(country => ({
        value: country.code,
        text: country.name,
      })),
    );
  };

  if (!countriesLoaded) return <></>;

  return (
    <SearchInput
      style={style}
      disabled={disabled}
      mode={mode}
      placeholder={t('chooseCountry')}
      initOn={countries}
      value={value}
      fetch={getCountriesInputData}
      onChange={onChange}
    />
  );
};
