import { useBaseAPIClient, APIRequestResult } from './index';
import { NotificationList } from '../UseNotificationsContext';

type NotificationsAPIClient = {
  listUnread: (page: number, pageSize: number) => Promise<APIRequestResult<NotificationList>>;
  countUnread: () => Promise<APIRequestResult<number>>;
  read: (id: number) => Promise<APIRequestResult>;
  readAll: () => Promise<APIRequestResult>;
};

export const useNotificationsAPIClient = (origin?: string): NotificationsAPIClient => {
  const { putRequest, getRequest, buildPaginatedUrl } = useBaseAPIClient({ origin });

  const listUnread = (page: number, pageSize: number): Promise<APIRequestResult<NotificationList>> =>
    getRequest<NotificationList>(buildPaginatedUrl('notifications/unread/', page, pageSize));

  const countUnread = (): Promise<APIRequestResult<number>> => getRequest('notifications/unread/count');

  const read = (id: number): Promise<APIRequestResult> => putRequest(`notifications/read/${id}`, {});

  const readAll = (): Promise<APIRequestResult> => putRequest('notifications/read/all');

  return {
    listUnread,
    countUnread,
    read,
    readAll,
  };
};
