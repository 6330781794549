import { Source } from './Meetings/types';

export type ProspectClientListItem = {
  id: number;
  firstName: string;
  lastName: string;
  status: number;
  statusName: string;
  subStatus?: number;
  subSstatusName?: string;
  comments?: string;
  importantComments?: string;
  email?: string;
  mainRoleId?: number;
  mainRoleName?: string;
  prospectCompanyId: number;
  prospectCompanyName: string;
  linkedInProfile: string;
  angelList?: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  updatedAt: string;
  updatedByUserId: string;
  updatedByUserName: string;
  pinned: boolean;
};

export type ProspectClientCreate = {
  firstName: string;
  lastName: string;
  status?: number;
  comments?: string;
  importantComments?: string;
  email?: string;
  picture?: string;
  mainRoleId?: number;
  prospectCompanyId?: number;
  linkedInProfile: string;
  angelList?: string;
};

export type ProspectClientWithCompanyCreate = ProspectClientCreate & {
  companyName?: number;
  companyLinkedInId?: string;
  companyLogoUrl?: string;
  companyLinkedInProfile?: string;
};

export type ProspectClientUpdate = {
  id: number;
  firstName: string;
  lastName: string;
  status: number;
  comments?: string;
  importantComments?: string;
  email?: string;
  mainRoleId?: number;
  prospectCompanyId: number;
  linkedInProfile: string;
  angelList?: string;
};

export type ProspectClient = {
  id: number;
  firstName: string;
  lastName: string;
  status: number;
  statusName: string;
  email?: string;
  mainRoleId?: number;
  mainRoleName?: string;
  comments?: string;
  importantComments?: string;
  linkedInProfile: string;
  angelList?: string;
  activitiesCount: number;
  scheduledTasksCount: number;
  prospectCompanyId: number;
  prospectCompanyName: string;
  prospectCompanyLinkedInProfile: string;
  prospectCompanyLinkedInCompanyId: string;
  title?: string;
  tags?: Array<string>;
  department?: string;
  seniority?: string;
  website?: string;
  alternativeEmails?: string;
  alternativePhones?: string;
  createdAt: string;
  createdByUserId: string;
  createdByUserName: string;
  updatedAt: string;
  updatedByUserId: string;
  updatedByUserName: string;
  meetingsCount: number;
  pinned: boolean;
  ownedAt?: string;
  ownedByUserId?: string;
  ownedByUserName?: string;
  ownedByUserPictureUrl?: string;
  ownersHistoryCount: number;
  source?: Source;
};

export type ProspectClientByLinkedInProfile = {
  id: number;
  firstName: string;
  lastName: string;
  status: number;
  statusName: string;
  createdAt: string;
  updatedAt: string;
  updatedByUserName: string;
  lastActivityContactCreatedAt?: string;
  lastActivityContactCreatedByUserName?: string;
  picture?: string;
  linkedInProfile: string;
  prospectCompanyName: string;
  ownedAt?: string;
  prospectCompanyId: number;
  ownedByUserId?: string;
  ownedByUserName?: string;
  importantComments?: string;
  prospectCompanyLinkedInCompanyId: string;
  prospectCompanyLinkedInProfile?: string;
  mainRoleName?: string;
  mainRoleId?: number;
  tags?: Array<string>;
  age: number;
};

export type ProspectClientLinkedInUpdate = {
  id: number;
  status: Status;
  mainRoleId: number;
};

export enum Status {
  pool = 1,
  requestSent,
  messageSent,
  emailSent,
  active,
  notInterested,
  notInterestedContactInTheFuture,
  weAreNotInterested,
  noResponse,
  client,
}

export enum RouteStatus {
  next = 'next',
  past = 'past',
}

export enum ProspectClientType {
  pool = 'pool',
  all = 'all',
}

export type AdvancedSearchFilters = {
  createdByUserId?: string;
  ownedByUserIds?: Array<string>;
  ownedAtFrom?: string;
  ownedAtTo?: string;
  sources?: Array<Source>;
  createdAtFrom?: string;
  createdAtTo?: string;
  updatedAtFrom?: string;
  updatedAtTo?: string;
  statuses: Array<Status>;
  tags: Array<string>;
};

export type MeetingsAdvancedSearchFilters = {
  prospectClientFirstName?: string;
  prospectClientLastName?: string;
  statuses: Array<number>;
  prospectClientStatus: Array<number>;
  source: Array<Source>;
  prospectClientOwnedByUserIds?: Array<string>;
  prospectCompanyIds?: Array<number>;
  prospectCompanyTypes?: Array<number>;
  mainRolesIds?: Array<number>;
  from?: string;
  to?: string;
};
