import React, { ReactElement, useEffect, useState } from 'react';
import { List, Button } from 'antd';
import { Pages, Modules } from '../../routerTypes';
import { useNotificationsContext, Notification, useDateFormatter } from '../../hooks';
import { Roles } from '../../hooks/auth/types';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '../../hooks/auth/Auth0Provider';
import { useNavigate } from 'react-router-dom';
import './css/Notifications.css';

interface NotificationListProps {
  onReadAllNotifications: () => void;
}

const NotificationList: React.FC<NotificationListProps> = ({ onReadAllNotifications }) => {
  const [isLoadingNotifications, setIsLoadingNotifications] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, setCurrentPageSize] = useState<number>(4);

  const { t } = useTranslation();
  const { userIsInRole } = useAuth0();
  const navigate = useNavigate();
  const { toShortLocalDateTime } = useDateFormatter();

  const { notificationsCount, notifications, readNotification, readAllNotifications, listUnread } =
    useNotificationsContext();

  const loadPage = async (page: number, pSize: number): Promise<void> => {
    if (listUnread) {
      await listUnread(page, pSize);
    }

    setCurrentPage(page);
    setCurrentPageSize(pSize);
  };

  useEffect(() => {
    loadPage(1, pageSize);
  }, []);

  const handleReadNotification = async (notificationId: number): Promise<void> => {
    if (!readNotification) return;

    await readNotification(notificationId);

    if (notifications.length === 1 && currentPage !== 1) {
      loadPage(currentPage - 1, pageSize);
    } else {
      loadPage(currentPage, pageSize);
    }
  };

  const handleReadAllNotifications = async (): Promise<void> => {
    if (!readAllNotifications) return;

    setIsLoadingNotifications(true);
    await readAllNotifications();
    onReadAllNotifications();
    setIsLoadingNotifications(false);
  };

  const onPageChange = async (page: number, pageSize: number): Promise<void> => {
    setIsLoadingNotifications(true);
    await loadPage(page, pageSize);
    setIsLoadingNotifications(false);
  };

  const navigateTo = (url: string): void => {
    navigate(url);
  };

  const getActions = (notification: Notification): Array<ReactElement> => {
    const actions: Array<ReactElement> = [];

    if (notification.metadata) {
      const data = JSON.parse(notification.metadata);

      if (!userIsInRole(Roles.companyAdministrator)) {
        data?.candidateId &&
          actions.push(
            <Button
              type='link'
              key={data.candidateId}
              onClick={(): void => navigateTo(`${Pages[Modules.candidates].path}/${data.candidateId}/view`)}
            >
              {' '}
              {t('candidate')}
            </Button>,
          );
      }

      data?.jobOfferId &&
        actions.push(
          <Button
            type='link'
            key={data.jobOfferId}
            onClick={(): void => {
              if (userIsInRole(Roles.companyAdministrator)) {
                navigateTo(
                  `${Pages[Modules.companyView].path}${Pages[Modules.jobOffers].path}/${data.jobOfferId}/details`,
                );
              } else {
                navigateTo(`${Pages[Modules.jobOffers].path}/${data.jobOfferId}/view`);
              }
            }}
          >
            {' '}
            {t('jobOffer')}
          </Button>,
        );

      if (userIsInRole(Roles.companyAdministrator)) {
        data?.jobApplicationId &&
          data?.jobOfferId &&
          actions.push(
            <Button
              type='link'
              key={data.candidateId}
              onClick={(): void =>
                navigateTo(
                  `${Pages[Modules.companyView].path}${Pages[Modules.jobOffers].path}/${
                    data.jobOfferId
                  }/job-application/${data.jobApplicationId}`,
                )
              }
            >
              {' '}
              {t('jobApplication')}
            </Button>,
          );
      }
    }

    actions.push(
      <Button
        type='link'
        key={notification.id}
        className='signout'
        onClick={(): Promise<void> => handleReadNotification(notification.id)}
      >{`${t('done')}!`}</Button>,
    );

    return actions;
  };

  return (
    <div id='Notifications'>
      {notifications.length > 1 && (
        <Button type='link' key='all' className='signout' onClick={(): Promise<void> => handleReadAllNotifications()}>
          {`${t('clearAllNotifications')}`}
        </Button>
      )}
      <List
        className='notifications-container'
        loading={isLoadingNotifications}
        dataSource={notifications}
        itemLayout='vertical'
        pagination={{
          position: 'top',
          defaultCurrent: 1,
          defaultPageSize: pageSize,
          current: currentPage,
          total: notificationsCount,
          hideOnSinglePage: true,
          simple: true,
          pageSizeOptions: [4, 10, 20, 50],
          onChange: onPageChange,
        }}
        renderItem={(notification: Notification): React.ReactNode => (
          <List.Item className='notification' key={notification.id} actions={getActions(notification)}>
            <List.Item.Meta
              title={
                <div>
                  {notification.title}
                  <span className='date'>{toShortLocalDateTime(notification.createdAt)}</span>
                </div>
              }
              description={
                <div className='notification-description'>
                  <span>{notification.message}</span>
                  <span className='user'>
                    {' '}
                    - {t('by')}: {notification.createdByUserName}
                  </span>
                </div>
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default NotificationList;
