import { useBaseAPIClient, APIRequestResult } from './index';
import {
  AdvancedSearchFilters,
  JobApplication,
  JobApplicationListItem,
  JobApplicationReview,
  UpdateJobApplication,
} from '../../components/JobApplications/types';
import { KeyValuePair } from '../../components/Common';
import { TableSort } from '../../components/Common/types';
import { CustomAccessToken } from '../../components/JobApplications/types';

type JobApplicationsAPIClient = {
  get: (id: number) => Promise<APIRequestResult<JobApplication>>;
  update: (id: number, jobApplication: UpdateJobApplication) => Promise<APIRequestResult>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters: AdvancedSearchFilters,
  ) => Promise<APIRequestResult<Array<JobApplicationListItem>>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  list: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<JobApplicationListItem>>>;
  count: (filter?: string) => Promise<APIRequestResult<number>>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateOrder: (jobApplicationId: number, order: number) => Promise<APIRequestResult<void>>;
  getSubStatuses: (statusId: number | string) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateStatus: (
    id: number,
    status: number,
    subStatusesIds?: Array<number>,
    newOrder?: number,
  ) => Promise<APIRequestResult>;
  getByCandidate: (
    candidateId: number,
    page: number,
    pageSize: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ) => Promise<APIRequestResult<Array<JobApplicationListItem>>>;
  getByCandidateLite: (
    candidateId: number,
    filterStatuses?: Array<number>,
  ) => Promise<APIRequestResult<Array<JobApplicationListItem>>>;
  getByCandidateCount: (
    candidateId: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ) => Promise<APIRequestResult<number>>;
  getByJobOffer: (
    jobOfferId: number,
    page: number,
    pageSize: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ) => Promise<APIRequestResult<Array<JobApplicationListItem>>>;
  getByJobOfferLite: (
    jobOfferId: number,
    filterStatuses?: Array<number>,
  ) => Promise<APIRequestResult<Array<JobApplicationListItem>>>;
  getByJobOfferCount: (
    jobOfferId: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ) => Promise<APIRequestResult<number>>;
  getJobApplicationsCount: (candidateId: number) => Promise<APIRequestResult<number>>;
  getCustomAccessTokenForJobApplicationReview: (
    jobApplicationId: number,
  ) => Promise<APIRequestResult<CustomAccessToken>>;
  getJobApplicationReview: (
    customAccessToken: string,
    tenant: string,
  ) => Promise<APIRequestResult<JobApplicationReview>>;
  jobApplicationReview: (
    customAccessToken: string,
    review: JobApplicationReview,
    tenant: string,
    reCaptchaToken: string,
  ) => Promise<APIRequestResult<JobApplicationReview>>;
  getJobApplicationReviewById: (
    jobApplicationId: number,
    jobApplicationReviewId: number,
  ) => Promise<APIRequestResult<JobApplicationReview>>;
  getAppliedCompanyIds: (candidateId: number) => Promise<APIRequestResult<Array<number>>>;
};

interface Params {
  origin?: string;
  userCompanyId?: number;
}

export const useJobApplicationsAPIClient = ({ origin, userCompanyId }: Params = {}): JobApplicationsAPIClient => {
  const {
    putRequest,
    getRequest,
    postRequest,
    buildPaginatedUrl,
    buildQueryString,
    buildSortUrl,
    getFormatedFromDate,
    getFormatedToDate,
  } = useBaseAPIClient({ origin, userCompanyId });

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters: AdvancedSearchFilters,
  ): Promise<APIRequestResult<Array<JobApplicationListItem>>> => {
    const filters = {
      ...advancedSearchFilters,
      from: getFormatedFromDate(advancedSearchFilters.from),
      to: getFormatedToDate(advancedSearchFilters.to),
    };

    return postRequest<Array<JobApplicationListItem>>(
      buildPaginatedUrl(`job-applications/search`, page, pageSize),
      filters,
    );
  };

  const list = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<JobApplicationListItem>>> => {
    let url = buildPaginatedUrl('job-applications', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest(url);
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> => {
    const filters = {
      ...advancedSearchFilters,
      from: getFormatedFromDate(advancedSearchFilters.from),
      to: getFormatedToDate(advancedSearchFilters.to),
    };
    return postRequest<number>('job-applications/search/count', filters);
  };

  const count = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'job-applications/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest(url);
  };

  const get = (id: number): Promise<APIRequestResult<JobApplication>> => {
    return getRequest(`job-applications/${id}`);
  };

  const update = (id: number, jobApplication: UpdateJobApplication): Promise<APIRequestResult> => {
    return putRequest(`job-applications/${id}`, jobApplication);
  };

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest<Array<KeyValuePair>>('job-applications/statuses');
  };

  const getSubStatuses = (statusId: number | string): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest<Array<KeyValuePair>>(`job-applications/status/${statusId}/sub-statuses`);
  };

  const updateOrder = (jobApplicationId: number, order: number): Promise<APIRequestResult<void>> => {
    return putRequest<void>(`job-applications/${jobApplicationId}/order/${order}`);
  };

  const updateStatus = (
    id: number,
    status: number,
    subStatusIds?: Array<number>,
    newOrder?: number,
  ): Promise<APIRequestResult> => {
    return putRequest(`job-applications/${id}/status/${status}`, { subStatusIds, newOrder });
  };

  const getByCandidate = (
    candidateId: number,
    page: number,
    pageSize: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ): Promise<APIRequestResult<Array<JobApplicationListItem>>> => {
    const filters = {
      status,
      subStatusId,
      candidateStatus,
    };
    const url = buildPaginatedUrl(`job-applications/candidate/${candidateId}`, page, pageSize);
    return postRequest<Array<JobApplicationListItem>>(url, filters);
  };

  const getByCandidateLite = (
    candidateId: number,
    statusesFilter?: Array<number>,
  ): Promise<APIRequestResult<Array<JobApplicationListItem>>> => {
    let url = `job-applications/candidate/${candidateId}/lite`;

    if (statusesFilter) {
      url += buildQueryString('statuses', statusesFilter, true);
    }

    return getRequest<Array<JobApplicationListItem>>(url);
  };

  const getByCandidateCount = (
    candidateId: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ): Promise<APIRequestResult<number>> => {
    const body = {
      status,
      subStatusId,
      candidateStatus,
    };
    return postRequest<number>(`job-applications/candidate/${candidateId}/count`, body);
  };

  const getByJobOfferCount = (
    jobOfferId: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ): Promise<APIRequestResult<number>> => {
    const body = {
      status,
      subStatusId,
      candidateStatus,
    };
    return postRequest<number>(`job-applications/job-offer/${jobOfferId}/count`, body);
  };

  const getByJobOffer = (
    jobOfferId: number,
    page: number,
    pageSize: number,
    status?: number,
    subStatusId?: number,
    candidateStatus?: number,
  ): Promise<APIRequestResult<Array<JobApplicationListItem>>> => {
    const filters = {
      status,
      subStatusId,
      candidateStatus,
    };
    const url = buildPaginatedUrl(`job-applications/job-offer/${jobOfferId}`, page, pageSize);
    return postRequest<Array<JobApplicationListItem>>(url, filters);
  };

  const getByJobOfferLite = (
    jobOfferId: number,
    statusesFilter?: Array<number>,
  ): Promise<APIRequestResult<Array<JobApplicationListItem>>> => {
    let url = `job-applications/job-offer/${jobOfferId}/lite`;

    if (statusesFilter) {
      url += buildQueryString('statuses', statusesFilter, true);
    }

    return getRequest<Array<JobApplicationListItem>>(url);
  };
  const getJobApplicationsCount = (candidateId: number): Promise<APIRequestResult<number>> => {
    return getRequest<number>(`job-applications/active/candidate/${candidateId}/count`);
  };

  const getCustomAccessTokenForJobApplicationReview = (
    jobApplicationId: number,
  ): Promise<APIRequestResult<CustomAccessToken>> =>
    postRequest(`job-applications/${jobApplicationId}/review/accessToken`);

  const getJobApplicationReview = (
    customAccessToken: string,
    tenant: string,
  ): Promise<APIRequestResult<JobApplicationReview>> => {
    const config = {
      headers: [{ name: 'Tenant', value: tenant }],
      ignoreBearerToken: true,
    };

    return getRequest<JobApplicationReview>(`job-applications/review/${customAccessToken}`, config);
  };

  const jobApplicationReview = (
    customAccessToken: string,
    review: JobApplicationReview,
    tenant: string,
    reCaptchaToken: string,
  ): Promise<APIRequestResult<JobApplicationReview>> => {
    const config = {
      headers: [
        { name: 'Tenant', value: tenant },
        { name: 'ReCaptchaToken', value: reCaptchaToken },
      ],
      ignoreBearerToken: true,
    };

    return postRequest(`job-applications/review/${customAccessToken}`, review, config);
  };

  const getAppliedCompanyIds = (candidateId: number): Promise<APIRequestResult<Array<number>>> => {
    return getRequest<Array<number>>(`job-applications/candidate/${candidateId}/applied-company-ids`);
  };

  const getJobApplicationReviewById = (
    jobApplicationId: number,
    jobApplicationReviewId: number,
  ): Promise<APIRequestResult<JobApplicationReview>> => {
    return getRequest<JobApplicationReview>(`job-applications/${jobApplicationId}/review/${jobApplicationReviewId}`);
  };

  return {
    list,
    count,
    search,
    searchCount,
    get,
    update,
    getStatuses,
    getSubStatuses,
    updateStatus,
    updateOrder,
    getByCandidate,
    getByCandidateLite,
    getByJobOffer,
    getByJobOfferLite,
    getAppliedCompanyIds,
    getJobApplicationsCount,
    getCustomAccessTokenForJobApplicationReview,
    getJobApplicationReview,
    jobApplicationReview,
    getJobApplicationReviewById,
    getByCandidateCount,
    getByJobOfferCount,
  };
};
