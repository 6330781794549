import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';

type TooltipForEllipsisProps = {
  title: string;
};

export const TooltipForEllipsis: React.FC<TooltipForEllipsisProps> = ({ title }) => {
  const [ellipsis, setEllipsis] = useState(false);
  const { Paragraph } = Typography;

  const getParagraph = () => <Paragraph ellipsis={{ onEllipsis: setEllipsis }}>{title}</Paragraph>;

  return (
    <>
      {ellipsis ? (
        <Tooltip title={title} placement='topLeft'>
          {getParagraph()}
        </Tooltip>
      ) : (
        getParagraph()
      )}
    </>
  );
};

export default TooltipForEllipsis;
