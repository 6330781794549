import React, { ReactElement } from 'react';
import { Select } from 'antd';
import { $enum } from 'ts-enum-util';
import { useTranslation } from 'react-i18next';
import { SelectProps } from 'antd';
import { SelectWithSearch } from './index';

interface SelectForEnumProps extends SelectProps<string | number | undefined> {
  onChange?(value: number | string | undefined): void;
  value?: number | string | undefined;
  enumType: any;
  mode?: 'multiple' | 'tags';
}

export const SelectForEnum = React.forwardRef<HTMLSelectElement, SelectForEnumProps>((props, ref) => {
  const { Option } = Select;
  const { t } = useTranslation();
  const { enumType, mode } = props;

  const optionsBuilder = <T extends Record<Extract<keyof T, string>, number>>(e: T): Array<ReactElement> => {
    return $enum(e)
      .getEntries()
      .map(data => (
        <Option key={data[1]} value={data[1]}>
          {t(data[0].toString())}
        </Option>
      ));
  };

  return (
    <SelectWithSearch mode={mode} {...props} ref={ref}>
      {optionsBuilder(enumType)}
    </SelectWithSearch>
  );
});
