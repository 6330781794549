import { AccessType, Page } from '../../routerTypes';
import { useSharedContext } from '../../hooks/UseSharedContext';
import { useNavigate, Outlet, PathMatch, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import { ReactElement } from 'react';

type AuthLayoutProps = {
  pageRender: Page;
  location: Location;
  matchRoute: PathMatch<string> | null;
};

const AuthLayout = (props: AuthLayoutProps): ReactElement | null => {
  const { pageRender, location, matchRoute, ...rest } = props;
  const { permissions, routeAccessType, roles, excludedRoles, plans, excludedPlans, redirectTo } = pageRender;

  const { setFormHasChanges } = useSharedContext();
  const navigate = useNavigate();
  const { pathname } = location;
  const routePermissions: string[] | undefined = permissions
    ? permissions.map(el => {
        return `${el.permission}:${el.target}`;
      })
    : undefined;

  if (!matchRoute && !pageRender) {
    return <Navigate replace to='/' />;
  }

  switch (routeAccessType) {
    case AccessType.redirect:
      setFormHasChanges(false);
      navigate(redirectTo as string, { replace: true });
      return null;
    case AccessType.public:
      return <Outlet />;
    case AccessType.private:
      return (
        <PrivateRoute
          path={pathname}
          permissions={routePermissions}
          roles={roles}
          excludedRoles={excludedRoles}
          plans={plans}
          excludedPlans={excludedPlans}
          {...rest}
        />
      );
    default:
      return (
        <PrivateRoute
          path={pathname}
          permissions={routePermissions}
          roles={roles}
          excludedRoles={excludedRoles}
          plans={plans}
          excludedPlans={excludedPlans}
          {...rest}
        />
      );
  }
};

export default AuthLayout;
