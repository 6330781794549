import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Upload, message, Row, Col, Tooltip, Divider, Typography, UploadFile } from 'antd';
import {
  DeleteOutlined,
  CopyOutlined,
  DownloadOutlined,
  FileTextOutlined,
  LinkOutlined,
  InboxOutlined,
} from '@ant-design/icons';
import { RcFile } from 'antd/es/upload';
import { APIRequestResult } from '../../hooks/api';
import { useErrorMessageHandler, useCopyToClipboard } from '../../hooks';
import getDocumentName from '../../utils/helper/getDocumentName';

import './css/DocumentUpload.css';

type DocumentUploadProps = {
  title: string;
  value?: string;
  description?: string;
  fileNameTitle?: string;
  fileRemoveTitle?: string;
  disabled?: boolean;
  required?: boolean;
  onUploadDocument: (file: File | Blob) => Promise<APIRequestResult<any> | void>;
  onChange?(value?: string): void;
};

export const DocumentUpload: React.FC<DocumentUploadProps> = ({
  title,
  value,
  description,
  fileNameTitle,
  fileRemoveTitle,
  disabled = false,
  required = false,
  onChange,
  onUploadDocument,
}) => {
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();
  const [documentListInternal] = useState<Array<UploadFile>>([]);
  const [documentList, setDocumentList] = useState<Array<UploadFile>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { copyStringToClipboard } = useCopyToClipboard();
  const { Paragraph } = Typography;
  const { Dragger } = Upload;

  useEffect(() => {
    if (value) {
      setDocumentList([
        {
          uid: value,
          size: 0,
          type: '',
          name: description ?? getDocumentName(value),
          url: value,
          status: 'done',
        },
      ]);
      return;
    }

    setDocumentList([]);
  }, [value]);

  const customRequest = async (options): Promise<void> => {
    setIsLoading(true);
    const res = await onUploadDocument(options.file as Blob);

    if (res?.hasError) {
      errorMessageHandler(res);
      setDocumentList([]);
      onChange && onChange();
      setIsLoading(false);
      return;
    }

    onChange && onChange(res?.data);

    if (options.onSuccess) {
      options.onSuccess(res?.data, new XMLHttpRequest());
    }

    setDocumentList([
      {
        uid: res?.data,
        size: 0,
        type: '',
        name: (options.file as RcFile).name,
        status: 'done',
      },
    ]);
    setIsLoading(false);
  };

  const beforeUpload = (file: RcFile): boolean => {
    if (documentList.length > 0) return false;

    const isPdfOrDoc =
      file.type === 'application/pdf' ||
      file.type === 'application/msword' ||
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    if (!isPdfOrDoc) {
      message.error(`${t('uploadDocumentMessage')}!`);
      return false;
    }

    const isLt5M = file.size / 1024 / 1024 < 5;

    if (!isLt5M) {
      message.error(`${t('documentSizeMessage')}!`);
      return false;
    }

    return true;
  };

  const onRemove = (): void => {
    onChange && onChange();
    setDocumentList([]);
  };

  const copyUrl = (url?: string): void => {
    url && copyStringToClipboard(url);
  };

  return (
    <div id='DocumentUpload'>
      {documentList.length === 0 && (
        <Row className='upload-button-area'>
          <Col span={24}>
            <div className='document-uploader'>
              <Dragger
                name='documentUploader'
                accept='.pdf,.doc,.docx'
                className='document-uploader-ctrl'
                defaultFileList={documentListInternal}
                multiple={false}
                beforeUpload={beforeUpload}
                disabled={disabled || isLoading}
                customRequest={options => {
                  customRequest(options);
                }}
                onRemove={onRemove}
              >
                <Row className='upload-text-area'>
                  <Col span={24}>
                    <div className='upload-text-area-title'>
                      {required && <span style={{ color: 'red' }}>* </span>} <LinkOutlined /> {title}
                    </div>
                    <div className='upload-text-area-sub-title'>{`(${t('pdfOrWord')})`}</div>
                  </Col>
                </Row>
                <p className='ant-upload-drag-icon'>
                  <InboxOutlined />
                </p>
                <p style={{ color: '#4f4f4f' }}>{t('clickOrDragFile')}</p>
              </Dragger>
            </div>
          </Col>
        </Row>
      )}
      {documentList.length !== 0 &&
        documentList.map(file => (
          <div key={file.uid} className='file-container'>
            {file.url ? (
              <Row className='saved-file' align='middle'>
                <Col span={16} className='file-name'>
                  <Paragraph ellipsis style={{ marginBottom: 0 }}>
                    <a
                      href={file.url}
                      className='file-name-link'
                      key={file.url}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <FileTextOutlined style={{ fontSize: 24 }} /> {file.name}
                    </a>
                  </Paragraph>
                </Col>
                <Col span={8} className='saved-file-icons'>
                  <div className='saved-file-icons-container'>
                    <Tooltip placement='top' title={t('copyLink')}>
                      <CopyOutlined className='copy-link-resume' onClick={() => copyUrl(file.url)} />
                    </Tooltip>
                    <Divider type='vertical' className='icon-divider' />
                    <Tooltip placement='top' title={t('download')}>
                      <a href={`${file.url}?avoidPreview=true`} key={file.url} target='_blank'>
                        <DownloadOutlined className='download-icon' />
                      </a>
                    </Tooltip>
                    <Divider type='vertical' className='icon-divider' />
                    <Tooltip placement='top' title={fileRemoveTitle ?? t('removeResume')}>
                      <DeleteOutlined className='remove-resume' onClick={() => onRemove()} />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row className='saved-file'>
                <Col span={18}>
                  <Tooltip placement='top' title={fileNameTitle ?? `${t('view')} ${t('resume')}`}>
                    <span>
                      <Paragraph style={{ paddingLeft: '1rem', textAlign: 'start' }} ellipsis>
                        {file.name}
                      </Paragraph>
                    </span>
                  </Tooltip>
                </Col>
                <Col span={6} className='saved-file-icons'>
                  <div className='saved-file-icons-container'>
                    <Tooltip placement='top' title={fileRemoveTitle ?? t('removeResume')}>
                      <DeleteOutlined className='remove-resume' onClick={() => onRemove()} />
                    </Tooltip>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        ))}
    </div>
  );
};
