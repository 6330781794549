import React, { useEffect, useState, useContext, ReactElement } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { message, notification, Button } from 'antd';
import { useLoggly } from 'react-loggly-jslogger';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from './auth/Auth0Provider';
import { useNotificationsAPIClient } from './api';
import { useErrorMessageHandler } from '.';
import { useLocation } from 'react-router-dom';
import { Modules, Pages } from '../routerTypes';
import { Roles } from './auth/types';
import useRTMTenantHandler from './useRTMTenantHandler';

const browserLang = navigator.language;

export type Notification = {
  id: number;
  userId?: string;
  type?: string;
  title?: string;
  refererId?: number;
  message?: string;
  read: boolean;
  metadata?: string;
  createdAt: string;
  createdByUserName?: string;
};

export type NotificationList = {
  count: number;
  notifications: Array<Notification>;
};

interface PushNotification {
  id: number;
  type: string;
  title: PushNotificationTitle;
  message: PushNotificationMessage;
  refererId?: number;
  metadata?: string;
}

interface PushNotificationMessage {
  en: string;
  es: string;
}

interface PushNotificationTitle {
  en: string;
  es: string;
}

interface NotificationsContextProps {
  notificationsCount: number;
  notifications: Array<Notification>;
  listUnread?: (page: number, pageSize: number) => Promise<void>;
  readNotification?: (notificationId: number) => Promise<void>;
  readAllNotifications?: () => Promise<void>;
}

const notificationsContext = React.createContext<NotificationsContextProps>({
  notificationsCount: 0,
  notifications: [],
});

export const NotificationsContextProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const { getTokenSilently, isAuthenticated, userIsInRole, isTenantActive, isUserActive, isUserRegistered } =
    useAuth0();
  const { error } = useLoggly();
  const [notificationsCount, setNotificationsCount] = useState<number>(0);
  const [notifications, setNotifications] = useState<Array<Notification>>([]);
  const { t } = useTranslation();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { handleRTMTenant } = useRTMTenantHandler();
  const location = useLocation();

  const notificationsAPIClient = useNotificationsAPIClient();

  const hubConnection = new HubConnectionBuilder()
    .withUrl(`${process.env.REACT_APP_WEBSTARTED_SERVER_URL}/hubs/notifications`, {
      accessTokenFactory: () => getTokenSilently(),
    })
    .withAutomaticReconnect()
    .build();

  const increaseNotificationsCount = (): void => {
    setNotificationsCount(count => count + 1);
  };

  const decreaseNotificationsCount = (): void => {
    setNotificationsCount(count => count - 1);
  };

  const notifyNewRelease = (version: string): void => {
    notification.info({
      message: 'WebstartedHR',
      duration: 0,
      description: `${t('newVersionNotificationMessage')} (v${version})`,
      onClick: () => {
        window.sessionStorage.clear();
        window.location.reload();
      },
      onClose: () => {
        window.sessionStorage.clear();
        window.location.reload();
      },
      style: {
        cursor: 'pointer',
      },
    });
  };

  const getActions = (pushNotification: PushNotification, key: string): ReactElement => {
    const actions: Array<ReactElement> = [];

    if (pushNotification.metadata) {
      const data = JSON.parse(pushNotification.metadata);

      if (!userIsInRole(Roles.companyAdministrator)) {
        data?.candidateId &&
          actions.push(
            <Button
              type='link'
              key={data.candidateId}
              onClick={() => {
                readNotification(pushNotification.id);
                notification.destroy(key);
                window.open(`${Pages[Modules.candidates].path}/${data.candidateId}/view`, '_blank');
              }}
            >
              {' '}
              {t('candidate')}
            </Button>,
          );
      }

      data?.jobOfferId &&
        actions.push(
          <Button
            type='link'
            key={data.jobOfferId}
            onClick={() => {
              if (userIsInRole(Roles.companyAdministrator)) {
                readNotification(pushNotification.id);
                notification.destroy(key);
                window.open(
                  `${Pages[Modules.companyView].path}${Pages[Modules.jobOffers].path}/${data.jobOfferId}/details`,
                  '_blank',
                );
              } else {
                window.open(`${Pages[Modules.jobOffers].path}/${data.jobOfferId}`, '_blank');
              }
            }}
          >
            {' '}
            {t('jobOffer')}
          </Button>,
        );

      if (userIsInRole(Roles.companyAdministrator)) {
        data?.jobApplicationId &&
          data?.jobOfferId &&
          actions.push(
            <Button
              type='link'
              key={data.candidateId}
              onClick={() => {
                readNotification(pushNotification.id);
                notification.destroy(key);
                window.open(
                  `${Pages[Modules.companyView].path}${Pages[Modules.jobOffers].path}/${
                    data.jobOfferId
                  }/job-application/${data.jobApplicationId}`,
                  '_blank',
                );
              }}
            >
              {' '}
              {t('jobApplication')}
            </Button>,
          );
      }
    }

    actions.push(
      <Button
        type='primary'
        size='small'
        onClick={() => {
          readNotification(pushNotification.id);
          notification.destroy(key);
        }}
      >
        {t('confirm')}
      </Button>,
    );

    return <>{actions}</>;
  };

  const notify = (
    pushNotification: PushNotification,
    onClick?: (key: string) => void,
    onClose?: (key: string) => void,
  ): void => {
    const key = `${pushNotification.type}_${pushNotification.id}`;
    const currentLanguage = getCurrentLanguage();

    notification.info({
      message: pushNotification.title ? pushNotification.title[currentLanguage] : 'WebstartedHR',
      duration: 30,
      description: pushNotification.message[currentLanguage],
      key,
      btn: getActions(pushNotification, key),
      onClick: () => {
        if (onClick) {
          onClick(key);
        }
      },
      onClose: () => {
        if (onClose) {
          onClose(key);
        }
      },
    });
  };

  const getCurrentLanguage = (): string => {
    if (browserLang.indexOf('es') !== -1) return 'es';

    return 'en';
  };

  const handleNotification = (pushNotification: PushNotification): void => {
    if (pushNotification.type === 'NewRelease') {
      notifyNewRelease(pushNotification.message.en);
      return;
    }

    notify(pushNotification);
  };

  hubConnection.on('IncreaseNotificationsCount', args => handleRTMTenant(args, increaseNotificationsCount));
  hubConnection.on('Notify', args => handleRTMTenant(args, handleNotification));

  useEffect(() => {
    async function loadNotifications(): Promise<void> {
      const res = await notificationsAPIClient.countUnread();

      if (res.hasError) {
        errorMessageHandler(res);
        return;
      }

      if (res.data) {
        setNotificationsCount(res.data);
      }

      hubConnection.start().catch(err => error(err));
    }

    if (!isAuthenticated) return;

    if (!isUserRegistered()) return;

    if (!isTenantActive()) return;

    if (!isUserActive()) return;

    //Disable notifications for linkedinextension path
    if (location.pathname.includes('/linkedin-extension')) return;

    loadNotifications();
  }, [isAuthenticated]);

  const listUnread = async (page: number, pageSize: number): Promise<void> => {
    const res = await notificationsAPIClient.listUnread(page, pageSize);

    if (res.hasError) {
      errorMessageHandler(res);
      return;
    }
    if (res.data) {
      setNotificationsCount(res.data.count);
      setNotifications(res.data.notifications);
    }
  };

  const readNotification = async (notificationId: number): Promise<void> => {
    const res = await notificationsAPIClient.read(notificationId);

    if (res.hasError) {
      errorMessageHandler(res);
      return;
    }

    decreaseNotificationsCount();
  };

  const readAllNotifications = async (): Promise<void> => {
    const res = await notificationsAPIClient.readAll();

    if (res.hasError) {
      errorMessageHandler(res);
      return;
    }

    message.success(`${t('notificationsDeleted')}!`);
    setNotificationsCount(0);
    setNotifications([]);
  };

  return (
    <notificationsContext.Provider
      value={{ notificationsCount, notifications, readNotification, readAllNotifications, listUnread }}
    >
      {children}
    </notificationsContext.Provider>
  );
};

export const useNotificationsContext = (): NotificationsContextProps => {
  const { notificationsCount, notifications, readNotification, readAllNotifications, listUnread } =
    useContext(notificationsContext);
  return { notificationsCount, notifications, readNotification, readAllNotifications, listUnread };
};
