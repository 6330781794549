export enum Modules {
  dashboard = 'dashboard',
  candidates = 'candidates',
  settings = 'settings',
  jobOffers = 'jobOffers',
  companyView = 'company',
  companies = 'companies',
  interviews = 'interviews',
  integrations = 'integrations',
  scheduledTasks = 'scheduledTasks',
  jobApplications = 'jobApplications',
  parameters = 'parameters',
  groups = 'groups',
  usersManagement = 'usersManagement',
  prospectCompanies = 'prospectCompanies',
  prospectClients = 'prospectClients',
  mailTemplates = 'mailTemplates',
  talentNetwork = 'talent',
  talentNetworkList = 'talent-network',
}

export const Pages = {
  [Modules.candidates]: {
    path: `/candidates`,
  },
  [Modules.jobOffers]: {
    path: '/job-offers',
  },
  [Modules.companyView]: {
    path: '/company',
  },
  [Modules.talentNetwork]: {
    path: '/talent',
  },
  [Modules.talentNetworkList]: {
    path: '/talent-network',
  },
  [Modules.companies]: {
    path: '/companies',
  },
  [Modules.settings]: {
    path: '/settings',
  },
  [Modules.dashboard]: {
    path: '/dashboard',
  },
  [Modules.prospectCompanies]: {
    path: '/prospect-companies',
  },
  [Modules.prospectClients]: {
    path: '/prospect-clients',
  },
  [Modules.interviews]: {
    path: '/interviews',
  },
  [Modules.integrations]: {
    path: '/integrations',
  },
  [Modules.scheduledTasks]: {
    path: '/tasks',
  },
  [Modules.jobApplications]: {
    path: '/job-applications',
  },
  [Modules.groups]: {
    path: '/groups',
  },
  [Modules.usersManagement]: {
    path: '/users-management',
  },
  [Modules.parameters]: {
    path: '/parameters',
  },
  [Modules.mailTemplates]: {
    path: '/mail-templates',
  },
};

export interface RouteParams {
  params: {
    [key: string]: string;
  };
}

export enum PermissionType {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
  assign = 'assign',
}

export enum TargetType {
  candidates = 'candidates',
  jobOffers = 'joboffers',
  companies = 'companies',
  interviews = 'interviews',
  scheduledTasks = 'scheduledtasks',
  jobApplications = 'jobapplications',
  groups = 'groups',
  prospectCompanies = 'prospectcompanies',
  prospectClients = 'prospectclients',
}

export enum Params {
  candidateId = 'candidateId',
  jobOfferId = 'jobOfferId',
  companyId = 'companyId',
  prospectCompanyId = 'prospectCompanyId',
  prospectClientId = 'prospectClientId',
  jobApplicationId = 'jobApplicationId',
  interviewId = 'interviewId',
  resumeFriendlyName = 'resumeFriendlyName',
  status = 'status',
  tenant = 'tenant',
  customAccessToken = 'customAccessToken',
  userId = 'userId',
  version = 'version',
  groupId = 'groupId',
  linkedInProfile = 'linkedInProfile',
  roleId = 'roleId',
  resourceSlug = 'resourceSlug',
}

export enum AccessType {
  public = 'public',
  private = 'private',
  redirect = 'redirect',
}

export type UserPermission = {
  permission: PermissionType;
  target: TargetType;
};

export enum LayoutType {
  home = 'home',
}

export type Page = {
  routeAccessType: AccessType;
  componentImportPath?: React.LazyExoticComponent<React.FC<any>>;
  path: string;
  redirectTo?: string;
  permissions?: UserPermission[];
  roles?: Array<string>;
  hideSidebar?: boolean;
  excludedRoles?: Array<string>;
  plans?: Array<string>;
  excludedPlans?: Array<string>;
  layout?: LayoutType;
  hideLayout?: boolean;
};
