import { Form, Input, InputRef } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type LinkedInProfileFormItemProps = {
  required?: boolean;
  requiredMessage?: string;
  label?: string;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: any;
  ref?: React.RefObject<InputRef>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const LinkedInProfileFormItem: React.FC<LinkedInProfileFormItemProps> = ({
  required,
  requiredMessage,
  label,
  name,
  placeholder,
  disabled,
  value,
  ref,
  onChange,
}) => {
  const { t } = useTranslation();

  const validateLinkedIn = (_, value) => {
    const linkedinUrlRegex = /^(https?:\/\/)?(www\.|es\.)?linkedin\.com\/(in\/|company\/)?([^\s]+)\/?$/;
    const linkedinProfileRegex = /^(?!.*:\/\/)(?!.*\.)[^\s]{3,100}$/;
    if (!value || linkedinUrlRegex.test(value) || linkedinProfileRegex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(t('pleaseInputAValidLinkedinProfile'));
  };

  return (
    <Form.Item
      label={label || t('linkedinProfile')}
      name={name || 'linkedInProfile'}
      rules={[
        {
          required: required,
          message: requiredMessage,
        },
        { validator: validateLinkedIn },
      ]}
    >
      <Input ref={ref} placeholder={placeholder} disabled={disabled} value={value} onChange={onChange} />
    </Form.Item>
  );
};
