export enum MeetingType {
  firstMeeting = 1,
  contractMeeting,
  syncAboutOffers,
  other,
}

export enum Source {
  linkedIn = 1,
  apollo,
  email,
  known,
  website,
  advertising,
  events,
  crunchbase,
  referral,
  other,
}

export type ProspectClientMeeting = {
  id: number;
  prospectClientId: number;
  prospectClientName?: string;
  prospectClientStatusName?: string;
  prospectClientOwnedByUserId?: string;
  prospectClientOwnedByUserName?: string;
  prospectClientLinkedInProfile?: string;
  organizerUserId?: string;
  organizerUserName?: string;
  status: number;
  statusName?: string;
  source?: string;
  sourceName?: string;
  type: number;
  typeName?: string;
  notes?: string;
  scheduledAt?: string;
  createdAt: string;
  updatedAt?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
};

export type ProspectClientMeetingCreate = {
  prospectClientId: number;
  status: number;
  mainRoleId: number;
  organizerUserId?: string;
  startedAt?: string;
  endedAt?: string;
  type: number;
  notes?: string;
  scheduledAt: string;
};

export type ProspectClientMeetingUpdate = ProspectClientMeetingCreate & { id: number };

export type ProspectClientMeetingListItem = {
  id: number;
  prospectClientId: number;
  prospectClientName?: string;
  prospectClientStatusName?: string;
  prospectClientOwnedByUserId?: string;
  prospectClientOwnedByUserName?: string;
  prospectClientOwnedByPictrue?: string;
  prospectClientLinkedInProfile?: string;
  organizerUserId?: string;
  organizerUserName?: string;
  organizerPicture?: string;
  status: number;
  statusName?: string;
  type: number;
  typeName?: string;
  notes?: string;
  scheduledAt: string;
  createdAt: string;
  updatedAt?: string;
  createdByUserId?: string;
  createdByUserName?: string;
  updatedByUserId?: string;
  updatedByUserName?: string;
};

export type AdvancedSearchFilters = {
  prospectClientFirstName?: string;
  prospectClientLastName?: string;
  statuses: Array<number>;
  prospectClientStatus: Array<number>;
  prospectClientOwnedByUserIds?: Array<string>;
  prospectCompanyIds?: Array<number>;
  prospectCompanyTypes?: Array<number>;
  organizerUserId?: string;
  mainRolesIds?: Array<number>;
  source: Array<Source>;
  from?: string;
  to?: string;
};
