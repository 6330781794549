import React from 'react';
import { Col, Drawer, Row } from 'antd';
import { useState } from 'react';
import { LoadingForDrawer } from '.';
import { DefaultDrawer } from '../../constants';
import LoadingOverlay from '@speedy4all/react-loading-overlay';
import { CloseOutlined } from '@ant-design/icons';
import { useSharedContext } from '../../hooks';

import './css/AppDrawer.css';

declare type EventType = React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>;

export interface AppDrawerProps {
  title: string;
  onClose?: (e?: EventType) => void;
  visible?: boolean;
  width?: number | string;
  overlay: boolean;
  forceRender?: boolean;
  children: React.ReactNode;
}

type ChildrenProps = {
  showOverlay: () => void;
  hideOverlay: () => void;
  showOnDrawer: boolean;
};

export const AppDrawer: React.FC<AppDrawerProps> = props => {
  const { children, title, width, visible, overlay, forceRender, onClose } = props;

  const [drawerOverlayVisible, setDrawerOverlayVisible] = useState<boolean>(false);

  const { showOverlay, hideOverlay } = useSharedContext();

  const showDrawerOverlay = () => {
    setDrawerOverlayVisible(true);
  };

  const hideDrawerOverlay = () => {
    setDrawerOverlayVisible(false);
  };

  const onCloseDrawer = (e?: EventType) => {
    setDrawerOverlayVisible(false);
    onClose && onClose(e);
  };

  if (!children) {
    return null;
  }

  if (!React.isValidElement(children)) {
    return null;
  }

  return (
    <Drawer
      title={
        <Row>
          <Col span={22}>{title}</Col>
          <Col style={{ textAlign: 'end' }} span={2}>
            <CloseOutlined className='ant-drawer-close' onClick={() => onCloseDrawer()} />
          </Col>
        </Row>
      }
      width={width ?? DefaultDrawer.DRAWER_WIDTH}
      placement='right'
      closable={false}
      onClose={onCloseDrawer}
      destroyOnClose
      forceRender={forceRender}
      visible={visible}
    >
      <div id='AppDrawer'>
        {overlay ? (
          <LoadingOverlay active={drawerOverlayVisible} spinner={<LoadingForDrawer />}>
            <div className='drawer-body'>
              {React.cloneElement(children as React.ReactElement<ChildrenProps>, {
                showOverlay: showDrawerOverlay,
                hideOverlay: hideDrawerOverlay,
                showOnDrawer: true,
              })}
            </div>
          </LoadingOverlay>
        ) : (
          <div className='drawer-body'>
            {React.cloneElement(children as React.ReactElement<ChildrenProps>, {
              showOverlay,
              hideOverlay,
              showOnDrawer: true,
            })}
          </div>
        )}
      </div>
    </Drawer>
  );
};
