import { useViewport } from './UseViewport';

type TableHeader = {
  showTableHeader: (maxWidthColumnsTitle?: number) => boolean;
};

export const useTableHeader = (): TableHeader => {
  const { isBreakpoint } = useViewport();

  const showTableHeader = (maxWidthColumnsTitle?: number) => {
    return isBreakpoint(maxWidthColumnsTitle || 1297);
  };

  return {
    showTableHeader,
  };
};
