import { useBaseAPIClient, APIRequestResult } from './index';
import {
  AddCompanyExecutive,
  AdvancedSearchFilters,
  Company,
  CompanyCreate,
  CompanyExecutive,
  CompanyInfo,
  CompanyListItem,
  ExcludedCompany,
} from '../../components/Companies/types';
import { KeyValuePair } from '../../components/Common';
import { TableSort } from '../../components/Common/types';
import { CreateExcludedCompany } from '../../components/Companies/types';

type CompaniesAPIClient = {
  get: (id: number) => Promise<APIRequestResult<Company>>;
  create: (company: CompanyCreate) => Promise<APIRequestResult<Company>>;
  update: (id: number, company: Company) => Promise<APIRequestResult<Company>>;
  list: (
    page: number,
    pageSize: number,
    filter?: string,
    tableSort?: TableSort,
  ) => Promise<APIRequestResult<Array<CompanyListItem>>>;
  listByExecutive: (userId?: string) => Promise<APIRequestResult<Array<CompanyListItem>>>;
  asKeyValues: (filter?: string) => Promise<APIRequestResult<Array<KeyValuePair>>>;
  count: (filter?: string) => Promise<APIRequestResult<number>>;
  search: (
    page: number,
    pageSize: number,
    advancedSearchFilters?: AdvancedSearchFilters,
    jobApplicationStatuses?: Array<number>,
    jobApplicationSubStatusIds?: Array<number>,
  ) => Promise<APIRequestResult<Array<CompanyListItem>>>;
  searchCount: (advancedSearchFilters: AdvancedSearchFilters) => Promise<APIRequestResult<number>>;
  excluded: () => Promise<APIRequestResult<Array<string>>>;
  remove: (id: number) => Promise<APIRequestResult>;
  getStatuses: () => Promise<APIRequestResult<Array<KeyValuePair>>>;
  updateStatus: (id: number, status: number) => Promise<APIRequestResult>;
  uploadFile: (file: File | Blob) => Promise<APIRequestResult<string>>;
  removeCompanyExecutive: (id: number, userId: string) => Promise<APIRequestResult>;
  addCompanyExecutives: (
    id: number,
    addCompanyExecutives: AddCompanyExecutive,
  ) => Promise<APIRequestResult<Array<CompanyExecutive>>>;
  cancelContract: (id: number) => Promise<APIRequestResult>;
  getExcludedCompanies: (id: number) => Promise<APIRequestResult<Array<ExcludedCompany>>>;
  createExcludedCompany: (
    id: number,
    excludedCompany: CreateExcludedCompany,
  ) => Promise<APIRequestResult<CreateExcludedCompany>>;
  deleteExcludedCompany: (companyId: number, excludedCompanyId: number) => Promise<APIRequestResult>;
  getInfo: () => Promise<APIRequestResult<CompanyInfo>>;
};
interface Params {
  origin?: string;
  userCompanyId?: number;
}

export const useCompaniesAPIClient = ({ origin, userCompanyId }: Params = {}): CompaniesAPIClient => {
  const {
    putRequest,
    postRequest,
    getRequest,
    deleteRequest,
    buildPaginatedUrl,
    buildSortUrl,
    uploadFileRequest,
    getFormatedToDate,
  } = useBaseAPIClient({ origin, userCompanyId });

  const list = (
    page: number,
    pageSize = 30,
    filter?: string,
    tableSort?: TableSort,
  ): Promise<APIRequestResult<Array<CompanyListItem>>> => {
    let url = buildPaginatedUrl('companies', page, pageSize);

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    if (tableSort) {
      url = buildSortUrl(url, tableSort.sortBy, tableSort.sortDirection);
    }

    return getRequest<Array<CompanyListItem>>(url);
  };

  const listByExecutive = (userId?: string): Promise<APIRequestResult<Array<CompanyListItem>>> => {
    let url = `companies/executive`;

    if (userId) {
      url = `${url}/${userId}`;
    }

    return getRequest<Array<CompanyListItem>>(url);
  };

  const asKeyValues = (filter?: string): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    let url = 'companies/askeyvalues';

    if (filter) {
      url = `${url}&filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<Array<KeyValuePair>>(url);
  };

  const removeCompanyExecutive = (id: number, userId: string): Promise<APIRequestResult> =>
    deleteRequest(`companies/${id}/executive/${userId}`);

  const addCompanyExecutives = (
    id: number,
    addCompanyExecutives: AddCompanyExecutive,
  ): Promise<APIRequestResult<Array<CompanyExecutive>>> =>
    putRequest(`companies/${id}/executives`, addCompanyExecutives);

  const count = (filter?: string): Promise<APIRequestResult<number>> => {
    let url = 'companies/count';

    if (filter) {
      url = `${url}?filter=${encodeURIComponent(filter)}`;
    }

    return getRequest<number>(url);
  };

  const get = (id: number): Promise<APIRequestResult<Company>> => {
    return getRequest<Company>(`companies/${id}`);
  };

  const create = (company: CompanyCreate): Promise<APIRequestResult<Company>> => {
    return postRequest<Company>('companies', company);
  };

  const remove = (id: number): Promise<APIRequestResult> => {
    return deleteRequest(`companies/${id}`);
  };

  const update = (id: number, company: Company): Promise<APIRequestResult<Company>> => {
    return putRequest<Company>(`companies/${id}`, company);
  };

  const getStatuses = (): Promise<APIRequestResult<Array<KeyValuePair>>> => {
    return getRequest<Array<KeyValuePair>>('companies/statuses');
  };

  const updateStatus = (id: number, status: number): Promise<APIRequestResult> => {
    return putRequest(`companies/${id}/status/${status}`);
  };

  const uploadFile = (file: File | Blob): Promise<APIRequestResult<string>> => {
    return uploadFileRequest(`companies/file`, file);
  };

  const excluded = (): Promise<APIRequestResult<Array<string>>> => {
    return getRequest('companies/excluded');
  };

  const cancelContract = (id: number): Promise<APIRequestResult> => putRequest(`companies/${id}/cancel-contract`);

  const getExcludedCompanies = (id: number): Promise<APIRequestResult<Array<ExcludedCompany>>> => {
    return getRequest(`companies/${id}/excluded-companies`);
  };

  const deleteExcludedCompany = (companyId: number, excludedCompanyId: number): Promise<APIRequestResult> => {
    return deleteRequest(`companies/${companyId}/excluded-company/${excludedCompanyId}`);
  };

  const createExcludedCompany = (
    id: number,
    excludedCompany: CreateExcludedCompany,
  ): Promise<APIRequestResult<CreateExcludedCompany>> => {
    return putRequest(`companies/${id}/excluded-company`, excludedCompany);
  };

  const search = (
    page: number,
    pageSize = 30,
    advancedSearchFilters?: AdvancedSearchFilters,
    jobApplicationStatuses?: Array<number>,
    jobApplicationSubStatusIds?: Array<number>,
  ): Promise<APIRequestResult<Array<CompanyListItem>>> => {
    const filters = {
      ...advancedSearchFilters,
      createdAtTo: getFormatedToDate(advancedSearchFilters?.createdAtTo),
      updatedAtTo: getFormatedToDate(advancedSearchFilters?.updatedAtTo),
      jobApplicationStatuses,
      jobApplicationSubStatusIds,
    };
    const url = buildPaginatedUrl('companies/search', page, pageSize);

    return postRequest<Array<CompanyListItem>>(url, filters);
  };

  const searchCount = (advancedSearchFilters: AdvancedSearchFilters): Promise<APIRequestResult<number>> => {
    const filters = {
      ...advancedSearchFilters,
      createdAtTo: getFormatedToDate(advancedSearchFilters.createdAtTo),
      updatedAtTo: getFormatedToDate(advancedSearchFilters.updatedAtTo),
    };
    return postRequest<number>('companies/search/count', filters);
  };

  const getInfo = (): Promise<APIRequestResult<CompanyInfo>> => getRequest('companies/info');

  return {
    list,
    listByExecutive,
    count,
    cancelContract,
    createExcludedCompany,
    deleteExcludedCompany,
    getExcludedCompanies,
    get,
    asKeyValues,
    create,
    excluded,
    remove,
    update,
    getStatuses,
    updateStatus,
    uploadFile,
    removeCompanyExecutive,
    addCompanyExecutives,
    search,
    searchCount,
    getInfo,
  };
};
