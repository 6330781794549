import { Tag, Tooltip } from 'antd';

import './css/CustomTag.css';

interface CustomTagProps {
  content?: Array<string>;
  tagColor?: string;
  textColor?: string;
  top?: number;
}

export const CustomTag: React.FC<CustomTagProps> = ({ content, tagColor, top, textColor }) => {
  if (!content || content.length === 0) return null;

  let tooltipContent: Array<string> = [];
  let orderedContent = content;
  let plusContentCount = 0;

  if (top && top > 0) {
    tooltipContent = orderedContent.slice(top).map(x => ` ${x} /`);
    orderedContent = orderedContent.slice(0, top);
    plusContentCount = content.length - top;
  }

  return (
    <div id='CustomTag' className='custom-tag-container'>
      {orderedContent.map((content, i) => (
        <Tag key={i} className='content-tag' color={tagColor || '#7f7f7f'} style={{ color: textColor || '#4f4f4f' }}>
          {content}
        </Tag>
      ))}

      {tooltipContent.length > 0 && (
        <Tooltip title={tooltipContent} placement='top'>
          <Tag key={'plus'} className='plus-tag' color={tagColor}>
            +{plusContentCount}
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default CustomTag;
