import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, message, Tooltip, Typography, Dropdown, Menu } from 'antd';
import {
  ApiOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  MonitorOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import {
  useViewport,
  useDateFormatter,
  useTableScrollY,
  useErrorMessageHandler,
  usePaginatedTableData,
  useSharedContext,
} from '../../hooks';
import { Assignation, AssignationStatus } from './types';
import { PaginatedTable } from './PaginatedTable';
import { IconButton, SearchInput, SelectForEnum } from '.';
import { useUsersInfo } from '../../hooks/UseUsersInfo';
import { useAssignationsAPIClient } from '../../hooks/api/AssignationsAPIClient';
import { useDialogBuilder } from '../../hooks/UseDialogBuilder';
import { PrivateMenuItem } from '../Auth';
import { Pages, Modules } from '../../routerTypes';
import { Plans } from '../../hooks/auth/types';
import { ColumnsType } from 'antd/es/table';

import './css/Table.css';
import './css/AssignationsList.css';

type AssignationsListProps = {
  userId?: string;
  forType: string;
  reloadTrigger?: boolean;
  statusFilter?: boolean;
  onAdvancedOptions?: (assignation: Assignation) => void;
};

export const AssignationsList: React.FC<AssignationsListProps> = ({
  userId,
  statusFilter,
  forType,
  reloadTrigger,
  onAdvancedOptions,
}) => {
  const { showOverlay, hideOverlay } = useSharedContext();

  const { t } = useTranslation();
  const { toLocalShortDate } = useDateFormatter();
  const { errorMessageHandler } = useErrorMessageHandler();
  const { width, height } = useViewport();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [selectedUserId, setSelectedUserId] = useState<string | undefined>(userId);
  const [selectedStatus, setSelectedStatus] = useState<number>();
  const { getUserSearchInputList } = useUsersInfo();
  const { confirmDialog } = useDialogBuilder();

  const title = `${t(forType)} - ${t('assignations')}`;

  const assignationsAPIClient = useAssignationsAPIClient(forType);

  const [tableScrollY, setTableScrollY] = useTableScrollY(undefined, 400, [
    'assignations-list-header',
    'ant-table-pagination-right',
  ]);

  const { Title } = Typography;

  const countAssignations = () =>
    selectedUserId
      ? assignationsAPIClient.countAssignedToUser(selectedUserId, selectedStatus)
      : assignationsAPIClient.countAssignations(selectedStatus);

  const searchAssignations = (page: number, size: number) =>
    selectedUserId
      ? assignationsAPIClient.getAssignedToUser(selectedUserId, page, size, selectedStatus)
      : assignationsAPIClient.getAssignations(page, size, selectedStatus);

  const { loadPage, setPage, resetPage, pageReseted, pageNumber, pageSize, count, page, isLoadingPage } =
    usePaginatedTableData<Assignation>(
      () => countAssignations(),
      (p, s) => searchAssignations(p, s),
    );

  useEffect(() => {
    showOverlay();
  }, []);

  useEffect(() => {
    if (isLoadingPage) {
      showOverlay();
    } else {
      hideOverlay();
    }
  }, [isLoadingPage]);

  useEffect(() => {
    if (isLoaded) {
      resetPage();
    }
  }, [selectedUserId, selectedStatus]);

  useEffect(() => {
    loadPage();
    setIsLoaded(true);
  }, [pageNumber, pageSize, pageReseted, reloadTrigger]);

  useEffect(() => {
    setTableScrollY();
  }, [width, height, isLoadingPage]);

  const assignedData = useCallback((assignation: Assignation) => {
    if (forType === 'candidates') {
      return (
        <a
          href={`${Pages[Modules.candidates].path}/${assignation.assignedId}/edit`}
          rel='noopener noreferrer'
          target='_blank'
          className='candidate-link'
        >
          <span>{assignation.assigned}</span>
        </a>
      );
    }

    return <span>{assignation.assigned}</span>;
  }, []);

  const onConfirmUnassign = useCallback(
    async (assignedId: number, assignedUserId: string): Promise<void> => {
      showOverlay();

      const res = await assignationsAPIClient.unassign(assignedId, assignedUserId);

      if (res.hasError) {
        errorMessageHandler(res);
        hideOverlay();
        return;
      }

      message.success(`${t(forType)} - ${t('unassigned')}!`);

      await loadPage();

      hideOverlay();
    },
    [page],
  );

  const onUnassign = useCallback(
    (id: number, fullName: string, assignedUserId: string) => {
      confirmDialog(t('confirmUnassign'), `${t('confirmUnassignMessage')} ${fullName}?`, () =>
        onConfirmUnassign(id, assignedUserId),
      );
    },
    [page],
  );

  const onConfirmApproveRequestedAssignation = useCallback(
    async (id: number): Promise<void> => {
      showOverlay();

      const res = await assignationsAPIClient.approveRequestedAssignation(id);

      if (res.hasError) {
        errorMessageHandler(res);
        hideOverlay();
        return;
      }

      message.success(`${t('assignationRequestApproved')}!`);

      await loadPage();

      hideOverlay();
    },
    [page],
  );

  const onApproveRequestedAssignation = useCallback(
    (id: number, fullName: string) => {
      confirmDialog(t('approveRequestedAssignation'), `${t('approveRequestedAssignationMessage')} ${fullName}?`, () =>
        onConfirmApproveRequestedAssignation(id),
      );
    },
    [page],
  );

  const onConfirmRejectRequestedAssignation = useCallback(
    async (id: number): Promise<void> => {
      showOverlay();

      const res = await assignationsAPIClient.rejectRequestedAssignation(id);

      if (res.hasError) {
        errorMessageHandler(res);
        hideOverlay();
        return;
      }

      message.success(`${t('assignationRequestRejected')}!`);

      await loadPage();

      hideOverlay();
    },
    [page],
  );

  const onRejectRequestedAssignation = useCallback(
    (id: number, fullName: string) => {
      confirmDialog(t('rejectRequestedAssignation'), `${t('rejectRequestedAssignationMessage')} ${fullName}?`, () =>
        onConfirmRejectRequestedAssignation(id),
      );
    },
    [page],
  );

  const MenuItems = ({ assignation }) => {
    return (
      <Menu className='default-table-dropdown-menu'>
        <PrivateMenuItem
          key='1'
          onClick={() => onApproveRequestedAssignation(assignation.id, assignation.assigned)}
          permissions={['assign:candidates']}
          excludedPlans={[Plans.silver]}
          icon={<CheckCircleOutlined className='accept' />}
        >
          {t('approve')}
        </PrivateMenuItem>
        <PrivateMenuItem
          key='2'
          onClick={() => onRejectRequestedAssignation(assignation.id, assignation.assigned)}
          permissions={['assign:candidates']}
          excludedPlans={[Plans.silver]}
          icon={<CloseCircleOutlined className='reject' />}
        >
          {t('reject')}
        </PrivateMenuItem>
        {onAdvancedOptions && (
          <PrivateMenuItem
            key='3'
            onClick={() => onAdvancedOptions(assignation)}
            permissions={['update:candidates']}
            icon={<MonitorOutlined className='accept' />}
          >
            {t('advancedOptions')}
          </PrivateMenuItem>
        )}
      </Menu>
    );
  };

  const desktopColumns: ColumnsType<any> = [
    {
      title: t('assignedUser'),
      key: 'assignedUser',
      width: '17%',
      fixed: 'left',
      render: (text, assignation: Assignation) => {
        return <span>{assignation.assignedUserName}</span>;
      },
    },
    {
      title: t('status'),
      key: 'status',
      width: '7%',
      render: (text, assignation: Assignation, index) => {
        return <span>{assignation.statusName}</span>;
      },
    },
    {
      title: t('assigned'),
      key: 'assigned',
      width: '15%',
      render: (text, assignation: Assignation, index) => {
        return assignedData(assignation);
      },
    },
    {
      title: t('reason'),
      className: 'reason',
      key: 'reason',
      width: '18%',
      render: (text, assignation: Assignation) => {
        return <span>{assignation.reason}</span>;
      },
    },
    {
      title: t('notes'),
      key: 'notes',
      width: '18%',
      render: (text, assignation: Assignation) => {
        return <span>{assignation.notes}</span>;
      },
    },
    {
      title: t('createdBy'),
      key: 'createdBy',
      width: '12%',
      render: (text, assignation: Assignation) => {
        return <span>{assignation.updatedByUserName}</span>;
      },
    },
    {
      title: t('creationDate'),
      key: 'createdAt',
      width: '10%',
      render: (text, assignation: Assignation) => {
        return <span>{toLocalShortDate(assignation.createdAt)}</span>;
      },
    },
    {
      key: 'actions',
      width: '3%',
      className: 'actionsTable',
      dataIndex: 'actions',
      render: (text, assignation: Assignation) => {
        return (
          <>
            {(assignation.status === AssignationStatus.direct || assignation.status === AssignationStatus.approved) && (
              <Tooltip title={t('unassign')} placement='left'>
                <IconButton
                  onClick={() => {
                    onUnassign(assignation.assignedId, assignation.assigned, assignation.assignedUserId);
                  }}
                  type='link'
                  className='unassign'
                >
                  <ApiOutlined />
                </IconButton>
              </Tooltip>
            )}

            {assignation.status === AssignationStatus.requested && (
              <Dropdown overlay={<MenuItems assignation={assignation} />}>
                <MoreOutlined />
              </Dropdown>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div id='AssignationsList'>
      <div id='header' className='assignations-list-header'>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} align='bottom'>
          <Col span={12}>
            <h1 className='assignations-list-header-title'>{title}</h1>
          </Col>
          <Col span={16} md={4} className='filter-container'>
            {statusFilter && (
              <>
                <Title className='filter-title'>{t('status')}</Title>
                <SelectForEnum
                  placeholder={t('select')}
                  enumType={AssignationStatus}
                  allowClear
                  onChange={v => setSelectedStatus(v as number | undefined)}
                />
              </>
            )}
          </Col>
          <Col span={16} md={8} className='filter-container'>
            {!userId && (
              <>
                <Title className='filter-title'>{t('assignedUser')}</Title>
                <SearchInput
                  placeholder={t('select')}
                  fetch={getUserSearchInputList}
                  onChange={v => setSelectedUserId(v as string)}
                />
              </>
            )}
          </Col>
        </Row>
      </div>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={24} sm={24} md={24} lg={24}>
          <PaginatedTable
            isLoading={false}
            columns={desktopColumns}
            dataSource={page}
            onPageChange={setPage}
            scrollY={tableScrollY}
            currentPage={pageNumber}
            pageSize={pageSize}
            totalCount={count}
            showHeader
          />
        </Col>
      </Row>
    </div>
  );
};

export default AssignationsList;
