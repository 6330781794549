export enum Roles {
  recruiter = 'Recruiter',
  externalRecruiter = 'External Recruiter',
  recruiterManager = 'Recruiter Manager',
  recruitingReferent = 'Recruiting Referent',
  externalRecruiterManager = 'External Recruiter Manager',
  externalInterviewer = 'External Interviewer',
  interviewer = 'Interviewer',
  administrator = 'Administrator',
  companyAdministrator = 'Company Administrator',
  salesAgent = 'Sales Agent',
  companyExecutive = 'Company Executive',
  companyManager = 'Company Manager',
  recruiterPoolManager = 'Recruiter Pool Manager',
  userAdministrator = 'User Administrator',
  talentNetworkCandidate = 'Talent Network Candidate',
}

export enum Plans {
  silver = 'Silver',
  gold = 'Gold',
  platinum = 'Platinum',
  enterprise = 'Enterprise',
}
