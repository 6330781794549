import React, { useState } from 'react';
import { useTableHeader, useViewport } from './index';
import { PageDefaultValues } from '../constants/DefaultTheme';

interface UseTableScrollY {
  (divHeader?: React.RefObject<HTMLDivElement>, initialScroll?: number, classesToRemove?: string[]): [
    number,
    () => void,
  ];
}

export const useTableScrollY: UseTableScrollY = (divHeader, initialScroll, classesToRemove) => {
  if (!initialScroll) {
    initialScroll = PageDefaultValues.defaultInitialScroll;
  }
  const [tableScrollViewport, setTableScrollViewport] = useState<number>(initialScroll);
  const { height, width } = useViewport();
  const { showTableHeader } = useTableHeader();

  const setTableScrollY = () => {
    const navBarHeight = document.getElementById('NavBar')?.clientHeight;

    if (!navBarHeight) return;

    const margin = width < 1300 ? height * 0.025 * 3 : height * 0.007 * 3;

    let scrollY = height - navBarHeight - margin;

    if (divHeader && divHeader.current) {
      scrollY -= divHeader.current.clientHeight;
    }

    classesToRemove?.forEach(c => {
      if (!c) return;

      const classHeight = document.getElementsByClassName(c)[0]?.clientHeight;

      if (classHeight) {
        scrollY -= classHeight;
      }
    });

    if (showTableHeader()) {
      const tableHeaderHeight = document.getElementsByClassName('ant-table-thead')[0]?.clientHeight;

      if (tableHeaderHeight) {
        scrollY -= tableHeaderHeight;
      }
    }

    setTableScrollViewport(scrollY);
  };

  return [tableScrollViewport, setTableScrollY];
};
