import { Tag, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { CandidateTechnology } from '../Candidates/types';
import { TechnologyInterest } from './types';
import { JobOfferTechnology } from '../JobOffers/types';

import './css/TechnologiesTag.css';

interface TechnologiesTagProps {
  technologies?: Array<CandidateTechnology | JobOfferTechnology>;
  tagColor?: string;
  textColor?: string;
  top?: number;
}

export const TechnologiesTag: React.FC<TechnologiesTagProps> = ({ technologies, tagColor, textColor, top }) => {
  const { t } = useTranslation();

  if (!technologies) return null;

  let tooltipTechnologies: Array<string> = [];

  let orderedTechnologies = technologies
    .filter((t: any) => t.interest !== TechnologyInterest.notInterested)
    .sort((t1, t2) => t2.experienceYears - t1.experienceYears);

  if (top && top > 0) {
    tooltipTechnologies = orderedTechnologies
      .slice(top, orderedTechnologies.length)
      .map((tech: CandidateTechnology | JobOfferTechnology) =>
        tech.experienceYears ? `${tech.description} ${tech.experienceYears}${t('y')} ` : `${tech.description} `,
      );
    orderedTechnologies = orderedTechnologies.slice(0, top);
  }

  return (
    <div id='TechnologiesTag'>
      {orderedTechnologies.map((tech: CandidateTechnology | JobOfferTechnology, i: number) => (
        <Tag
          style={{ maxWidth: '100%', whiteSpace: 'normal', textAlign: 'center', color: `${textColor}` }}
          key={i}
          className='technologies-tag'
          color={tagColor}
        >
          {tech.experienceYears ? `${tech.description} ${tech.experienceYears}${t('y')} ` : `${tech.description} `}
        </Tag>
      ))}

      {tooltipTechnologies.length > 0 && (
        <Tooltip title={tooltipTechnologies} placement='top'>
          <Tag key={'plus'} className='technologies-tag' color={tagColor}>
            +
          </Tag>
        </Tooltip>
      )}
    </div>
  );
};

export default TechnologiesTag;
